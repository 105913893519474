import React from 'react';
import { data } from './data';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

const { project } = data;

function Projects() {
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div>
        <h2 id="solutiontitle" className='title text-[32px] text-center leading-[1.2] font-normal pb-[4rem]'>OUR Projects</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3   md:items-center  md:mx-10 items-center relative bg-white'>

          {project.map((li, key) => (
            <div
              key={key}
              className={`mx-4 md:min-h-[520px]  text-left border-none rounded-[15px] h-auto transform duration-400 ease-in bg-white  shadow-lg hover:shadow-2xl cursor-pointer
             mb-2 mt-2`}
            >
              <img src={li.img} alt={li.altdata} className='border-none rounded-t-[15px] md:min-h-[250px] md:max-h-[250px]'/>
              <div className='px-4 pb-4'>
              <h5 className='font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19] capitalize'>{li.title}</h5>
              <p className='font-light text-[16px] mb-2 text-[#1B1C19]'>{li.description}</p>

              {li.link && (
                <p className='flex justify-end'>
                  <a href={li.link} target='_blank' rel="noreferrer" className='bg-[#1B1C19] text-white text-[18px] font-semibold border-none px-[25px] py-[10px] transition-all duration-500 ease-linear  rounded-[50px] mb-[0%] hover:bg-[#A8CA55]'>Open</a>
                </p>
              )}
              </div>
            </div>
          ))}


        </div>
        <div className=' flex justify-center my-16'>
          <Link to='/portfolio'>
            <button className=" relative bg-[#1B1C19] text-white text-[18px] font-semibold border-none px-[25px] py-[10px] transition-all duration-500 ease-linear  rounded-[50px] mb-[0%] hover:bg-[#A8CA55]" onClick={handleLinkClick}>Explore More Projects <span className='pl-1'><i class="fa fa-arrow-right"></i></span> </button>
          </Link>
        </div>

      </div>

    </>
  )
}

export default Projects;
