import mobeen from '../Markhors/mobeen.jpg'
import ahmed from '../Markhors/ahmed.jpeg'
import hamza from '../Markhors/hamza.jpg'
import awais from '../Markhors/awais.jpg'
import fasih from '../Markhors/fasih.jpeg'
import zain from '../Markhors/zain.jpg'
import jawad from '../Markhors/jawad.jpeg'
import ibrahim from '../Markhors/ibrahim.jpg'
import nouman from '../Markhors/noman.jpg'
import khalil from '../Markhors/khalil.jpg'
import haroon from '../Markhors/haroon.jpg'
import ansar from '../Markhors/ansar.jpeg'

export const Data = [
    {
        id:1,
        name: "Muhammad Mobeen",
        role:"Co-Founder | AI Developer",
        image:mobeen
    },
    {
        id:2,
        name: "Ahmed Rohail Awan",
        role:"Co-Founder | Web Developer",
        image:ahmed

    },
    {
        id:3,
        name: "Hamza Farooq",
        role:"Co-Founder | Designer",
        image:hamza
        
    },
    {
        id:4,
        name: "Awais Afzal",
        role:"Co-Founder | Andriod Developer",
        image:awais

    },
    {
        id:7,
        name: "Fasih Muhammad Virk",
        role:"Python | Django Developer",
        image:fasih

    },
    {
        id:5,
        name: "Zain ul Abaideen",
        role:"Mern Stack Developer",
        image:zain

    },
    {
        id:11,
        name: "Muhammad Ibrahim",
        role:"React | Next JS Developer",
        image:ibrahim
    },
    {
        id:6,
        name: "Jawad Ur Rehman",
        role:"UI/UX Designer",
        image:jawad
    },
    {
        id:12,
        name: "Ansar Hayat",
        role:"React developer",
        image:ansar
    },
    {
        id:10,
        name: "Nouman Khan",
        role:"frontend developer",
        image:nouman
    },
    {
        id:8,
        name: "Muhammad Haroon",
        role:"Cyber Security Analyst | Ethical Hacker, Pentester",
        image:haroon

    },
    {
        id:9,
        name: "Khalil Ur Rehman",
        role:"AI Developer | Python, LLM, Generative AI",
        image:khalil

    }
];