
import project10 from '../Portfolio/MarkhorProjects/mydriverental.jpeg';
import project11 from '../Portfolio/MarkhorProjects/cobracraze.jpeg';
import project12 from '../Portfolio/MarkhorProjects/mywisdomhub.png';
import brand1 from "./images/brand1.PNG";
import brand2 from "./images/brand2.PNG";
import brand3 from "./images/brand3.PNG";
import brand4 from "./images/brand4.PNG";
import brand5 from "./images/brand5.PNG";
import test1 from "./images/test1.png";
import test2 from "./images/test2.png";
import test3 from "./images/test3.png";
import test4 from "./images/test4.png";
import test5 from "./images/test5.png";
import test6 from "./images/test6.png";

export const data = {
  project: [
    {
      no: 12,
      img: project12,
      category: 'Web Apps',
      title: 'MY Wisdom Hub',
      description:"Dive into a world of insightful articles and thought-provoking discussions at MY Wisdom Hub. Explore a diverse range of topics, from technology trends to personal development tips.",
      btntitle: 'Explore',
      altdata: '12 Project',
      link:'https://mywisdomhub.com/',
  },
    {
      no: 10,
      img: project10,
      category: 'Web Apps',
      title: 'Rental Car Booking Website',
      description: "We're thrilled to provide a customized car rental solution. Our seamless database management is tailored exclusively for our valued client's convenience.",
      btntitle: 'View Details',
      altdata: '10 Project',
      link: 'https://mydriverentals.com/',
    },
    {
      no: 11,
      img: project11,
      category: 'Web Apps',
      title: 'CobraCraze Ecommerce',
      description: "Unleash the power of Cobracraze: Our premier ecommerce solution, customer satisfaction, effortless shopping experiences and unparalleled profitability",
      btntitle: 'View Details',
      altdata: '11 Project',
      link: 'https://cobracraze.com/',
    },
  ],
  services: [
    {
      no: 1,
      title: "Web & Mobile Apps",
      description:
        "We deliver responsive web apps, sites and a seamless mobile experience for iOS and Android",
      altdata: "Service1",
      img: brand1,
    },
    {
      no: 2,
      title: "UI/Ux Designing",
      description:
        "From prototypes-to branding , high- fidelities and product flows; we have you coverd!",
      altdata: "Service3",
      img: brand3,
    },
    {
      no: 3,
      title: "AI & Chatbots",
      description:
        "Develop the logic that powers your app-with custom algorithms to serve you app's unique needs.",
      altdata: "Service4",
      img: brand4,
    },

    {
      no: 4,
      title: "Strategy and Prototype",
      description:
        "We'll work with you to determine the right features to captivate future users and stay in budget.",
      altdata: "Service2",
      img: brand2,
    },
    {
      no: 5,
      title: "Ongoing Maintenance",
      description:
        "We're ready to support your diital product post-launch and beyond!",
      altdata: "Service4",
      img: brand5,
    },
  ],

  testominals: [
    {
      no: 1,
      name: "Jay Jackson",
      pic: test1,
      stars: 4,
      des: " “Their ability to take my ideas and put them into this type of technology was phenomenal. I have a visual of what my app can be, which is priceless. While explaining it verbally carries some weight, it’s worth a thousand words to hold the platform in my hand and show it to prospective clients or investors.” ",
      color: "#1E69FF",
    },
    {
      no: 2,
      name: "Shadi Azoum",
      pic: test2,
      stars: 5,
      des: "They’ve been very receptive and hands-on throughout the process. We use Slack to communicate and Confluence for project management tracking, and the tools that we’ve been using have been very effective. They’re very knowledgeable in the development field.",
      color: "#A8CA55",
    },
    {
      no: 3,
      name: "Anthony Gargano",
      pic: test3,
      stars: 5,
      des: "  “From every aspect of the launch to what the game would feel like, they came up with suggestions that would improve the app. I’ve worked with other companies like this before that just didn’t pay attention to the client. They paid attention to what I wanted and helped streamline and focus my idea.”  ",
      color: "#1E69FF",
    },
    {
      no: 4,
      name: "Nicole Longo",
      pic: test4,
      stars: 5,
      des: " “Their ability to take my ideas and put them into this type of technology was phenomenal. I have a visual of what my app can be, which is priceless. While explaining it verbally carries some weight, it’s worth a thousand words to hold the platform in my hand and show it to prospective clients or investors.” ",
      color: "#A8CA55",
    },
    {
      no: 5,
      name: "Colin Plover",
      pic: test5,
      stars: 5,
      des: "  They were available and willing to provide documentation early on as I sought funding. I appreciated they were willing to meet with me and collaborate with me before having us sign a contract. We appreciate their enthusiasm and high level of knowledge in the design and development space.  ",
      color: "#1E69FF",
    },
    {
      no: 6,
      name: "Silar Chapman",
      pic: test6,
      stars: 4,
      des: "  The team’s responsive service, accommodating approach and timely deliveries has made them a strong long-term partner for us. Chop Dawg adheres to the budget and vision for the solution. Their ability to grasp niche business needs distinguishes them from other vendors we have worked with.  ",
      color: "#A8CA55",
    },
  ],
};
