import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { BsChevronDown } from "react-icons/bs";

import markhor from "../../Pages/Home/images/markhor.PNG";
import ResponsiveNav from "./ResponsiveNav";
import { NavLinks } from "./NavLinks";
function Navbar() {
  
  return (
    <>
      <div className="  w-[100%]  mt-6 z-[999] bg-transparent">
        {/* <!-- navbar  --> */}
        <nav className=" text-xl md:text-lg xl:text-2xl mx-3 md:mx-10  items-center  z-50 mb-5">
          <div className="relative hidden lg:flex justify-between items-center">
            <Link to="/">
              {" "}
              <div
                id="title"
                className=" font-bold text-3xl tracking-wide flex items-center"
              >
                <span className="px-1">
                  <img
                    src={markhor}
                    alt=""
                    width={50}
                    height={45}
                    className="rounded-[100%] "
                  />
                </span>{" "}
                MarkHor
                <span className="text-[#D761DC] font-bold" id="title">
                  Verse{" "}
                </span>
              </div>
            </Link>
            <ul className="navdata flex list-none justify-around ml-5 items-center font-semibold space-x-14">

                {
                NavLinks.map((li,key)=>(

                  <>
                    <div className={`right group  no-underline text-black  hidden md:flex font-semibold`}>
         
               <div className="">
                 <Link
                  to={li.link}
                  class= {`hover:text-[#A8CA55] cursor-pointer ease-in-out `}
                >
                  <li key={key} className="flex">{li.name}  {li.submenu? <span className='text-xl md:mt-2 md:ml-2 inline'>
                <BsChevronDown ></BsChevronDown>
                </span>: <span></span>}</li>
                  
                 
                </Link>
                </div>
                {li.submenu && (
                   <div>
                        <div className='absolute top-8  right-[28%] hidden group-hover:md:block hover:md:block shadow-lg z-[999]'>
                          <div className='py-3'>
                                <div className=' w-4 h-4   absolute mt-5 rotate-45'></div>
                          
                                <div className=' p-4 flex flex-col  '>
                            {
                              li.sublink.map((mysublinks)=>(
                                  <div>
                                    <Link to={mysublinks.link}>
                                    <p className='text-lg font-normal  hover:text-[#A8CA55]'>
                                      {mysublinks.name}
                                    </p>
                                    <p className="pt-5"></p>
                                    </Link>
                                    </div>
                              )) 
                          }
                            </div>
                          
                          </div>
                          </div>
                          </div>

                 
               )
               }
               </div>
               </>
                ))
              }
               
               </ul>
            
               
               
                {/* <Link
                  to="/"
                  class=" hover:text-[#A8CA55] cursor-pointer ease-in-out "
                >
                  <li>Home</li>
                </Link>
                <Link
                  to="/about"
                  class=" hover:text-[#A8CA55] cursor-pointer ease-in-out"
                >
                  <li className="">About</li>
                </Link>
                <Link
                  to="/team"
                  class=" hover:text-[#A8CA55] cursor-pointer ease-in-out"
                >
                  <li className="">Team</li>
                </Link>

                <Link
                  to="/portfolio"
                  class=" hover:text-[#A8CA55] cursor-pointer ease-in-out"
                >
                  <li>Portfolio</li>
                </Link> */}
            
            <div>
              <Link to="/contact" class=" cursor-pointer">
                <button
                  className="px-[25px] py-[10px] bg-[#685fcd] text-white font-medium rounded-full text-[1.3vw] border-none hover:bg-[#A8CA55] transition-all duration-500 ease-in-out"
                  id="firstbtn"
                >
                  Schedule your Consultation
                </button>
              </Link>
            </div>
          </div>
          {/* mobile */}
          <ResponsiveNav />
        </nav>
      </div>
    </>
  );
}

export default Navbar;
