import React from 'react'
import { Data } from "./data/data"
function WorkshopDetails(props) {
  return (
    <>
      <div className="container mx-5 md:mx-10 mt-[5rem]">
        {Data.map((workshop, key) => (
          // Check if props.id matches workshop.id
          (props.id === workshop.id) ? (
            <div key={key}>
              <div className='lg:flex'>
                <div className='lg:w-[60%] w-[90%]'>
                  <img src={workshop.img} alt={workshop.title} />
                </div>
                <div className="mt-4 lg:ml-8 mr-8 lg:w-[40%]">
                  <div className='py-[16px] text-[1rem] flex w-[100%]'><div className='w-[2.5rem] flex items-center pl-[0.5rem]'><strong><i className='fa-solid fa-calendar-days'></i></strong></div><div> {workshop.date}</div></div>
                  <div className='py-[16px] text-[1rem] flex w-[100%]'><div className='w-[2.5rem] flex items-center pl-[0.5rem]'><strong><i className='fa-regular fa-clock'></i></strong></div><div> {workshop.time}</div></div>
                  <div className='py-[16px] text-[1rem] flex w-[100%]'><div className='w-[2.5rem] flex items-center pl-[0.5rem]'><strong><i className='fa-solid fa-location-dot'></i></strong></div><div> {workshop.venue}</div></div>
                  <div className='py-[16px] text-[1rem] flex w-[100%]'><div className='w-[2.5rem] flex items-center pl-[0.5rem]'><strong><i className='fa-solid fa-tag'></i></strong></div><div> {workshop.type}</div></div>
                  <div className='py-[16px] text-[1rem] flex w-[100%]'><div className='w-[2.5rem] flex items-center pl-[0.5rem]'><strong><i className='fa-solid fa-dollar-sign'></i></strong></div><div> {workshop.price}</div></div>
                  <button className="bg-[#D761DC] text-white ml-[0.5rem] mt-[16px] px-4 py-2 rounded-md hover:bg-black"><a href={workshop.registerationlink} target="_blank" rel="noopener noreferrer" className="text-white">Register Now</a></button>

                </div>
              </div>
            </div>
          ) : null
        ))}
      </div>


    </>
  )
}

export default WorkshopDetails
