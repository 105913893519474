import React from 'react'
import Form from './Form'
import Hire from './Hire'
// import second from './Images/contacthero-bg.png'

const Contact = () => {
    return (
        <div className="flex justify-center items-center h-auto pb-10 sm:pb-0 ">
            < div className='flex flex-col items-center w-screen ' >
                <div className='min-h-[90vh] pt-[30vh] flex flex-col gap-5 sm:gap-6 md:gap-10 px-8 md:px-20 lg:px-36 items-center' style={{ backgroundColor: '#ACCC5A' }}>
                <h2 id="solutiontitle" className='title text-[42px] text-center leading-[1.2] font-normal'>Hire the Top Talent!</h2>
                <p className='text-base sm:text-lg md:text-2xl text-center text-white'>At Markhorverse, we make things easier for clients to reach us. Get in touch with our business development team; they will surely assist you in hiring top remote developers. Contact us now.</p>
                </div>
                <Form />
                <Hire />
            </div>
        </div>
    )
}

export default Contact