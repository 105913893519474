import React from 'react';
import { data } from './data';

const { services } = data;

function Services() {
  return (
    <div className='text-[#1B1C19] mt-[3rem]'>
      <h4 className='title text-center text-[32px]'>Our Services</h4>

      <div className="grid grid-cols-[50%] justify-center md:flex md:flex-wrap md:justify-around md:mx-10 mt-16 ">
        {services.map((li, key) => (
          <div className='relative group mb-20 cursor-pointer' key={key}>
            <div className='relative w-[130px] h-[100px] -z-10 flex justify-center items-center m-auto'>
              <img src={li.img} alt={li.altdata} className='cursor-pointer'/>
            </div>
            <div className="mt-6">
              <p className='text-[16px] font-light cursor-pointer text-center'>{li.title}</p>
              <div className="absolute top-full left-0 px-2 py-2 bg-[#eaf1ff] text-[#1B1C19] rounded-lg text-center text-sm opacity-0 transition-opacity duration-300 group-hover:opacity-100 overflow-hidden">
                <p className='text-ellipsis z-10 cursor-pointer' >{li.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
