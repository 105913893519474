import React from 'react'
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
// import second from './Images/contacthero-bg.png'

const Hire = () => {
    const handleLinkClick = () => {
        scroll.scrollToTop();
      };
    return (
        <div className="flex flex-col  md:flex-row justify-center p-8 md:p-14 rounded-lg shadow-2xl w-4/5 mt-16 gap-10" style={{ backgroundColor: '#ACCC5A' }}>
            <div>
                <h2 id="solutiontitle" className='title text-[42px] leading-[1.2] font-normal text-black mb-4'>Rescue your company’s growth!</h2>
                <p className='text-base sm:text-lg md:text-xl text-white'>Let us know about your business plans on an introductory call, and we’ll lead the matching process.</p>
            </div>
            <button className="bg-[#9e97ed] hover:bg-black border hover:border-gray-50 text-white text-lg font-bold px-8 py-4 h-fit rounded-xl w-fit shrink-0 md:self-center" onClick={handleLinkClick}> <Link to="/contact">Hire a Pro</Link>  </button>
        </div>
    )
}

export default Hire