import Whatsapp from '../Contact/Images/whatsapp.svg'
import Phone from '../Contact/Images/phone.svg'
import Email from '../Contact/Images/email.svg'

const Form = () => {
    return (
        <div className="flex flex-col justify-center bg-white p-4 sm:p-6 lg:p-10 rounded-lg shadow-2xl w-4/5 mt-10">
            <h2 className="text-5xl font-bold mb-8">Reach Us!</h2>
                <div className="flex flex-col md:flex-row mb-4">
                    <div className="flex flex-col gap-5 basis-1/2 pr-4 mb-2 md:mb-0">
                        <a href="tel:923475615338">
                        <button className="bg-[#9e97ed] hover:bg-[#c0db7e] text-white text-[1rem] md:text-[1.3rem] font-bold px-6 py-5 rounded-xl w-full flex gap-2 lg:gap-5 justify-start items-center">
                            <img src={Phone} alt="Mobile No" className="w-5 lg:w-8 h-5 lg:h-8 self-center" /> Mobile No
                        </button>
                        </a>
                        <a href="https://wa.me/923475586847" target="_blank" rel="noreferrer">
                        <button className="bg-[#9e97ed] hover:bg-[#c0db7e] text-white text-[1rem] md:text-[1.3rem] font-bold px-6 py-5 rounded-xl w-full flex gap-2 lg:gap-5 justify-start items-center ">
                            <img src={Whatsapp} alt="Message" className="w-5 lg:w-8 h-5 lg:h-8 self-center" /> Send a Message
                        </button>
                        </a>
                        <a href="mailto:markhorverse@gmail.com" target="_blank" rel="noreferrer">
                        <button className="bg-[#9e97ed] hover:bg-[#c0db7e] text-white text-[1rem] md:text-[1.3rem] font-bold px-6 py-5 rounded-xl w-full flex gap-2 lg:gap-5 mb-4 justify-start items-center">
                            <img src={Email} alt="Email" className="w-5 lg:w-8 h-5 lg:h-8 self-center " />  markhorverse@gmail.com
                        </button>
                        </a>
                    </div>
                    <div className="basis-1/2">
            <form>
                        <input className="w-full px-6 py-4 border rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none" type="email" id="email" name="email" placeholder="Email" />
                        <input className="w-full px-6 py-4 border rounded-md mb-4 bg-gray-100 placeholder:text-gray-500 outline-none" type="text" id="company" name="company" placeholder="Company" />
                        <textarea className="w-full px-6 py-4 border rounded-md mb-4 h-40 bg-gray-100 placeholder:text-gray-500 outline-none" id="description" name="description" placeholder="Please tell us how can we help you?" />
                        <button className="bg-[#9e97ed] hover:bg-[#c0db7e] text-white text-lg font-bold p-4 rounded-lg w-full" type="submit">Submit</button>
            </form>
                    </div>
                </div>
        </div>
    )
}

export default Form