import React, { useEffect} from "react";
import "./home.css";
import Projects from "./OurProjects";
import Services from "./Services";
import Testominals from "./Testominals";
import Card from "../About/Components/Card";
import GLOBE from 'vanta/src/vanta.globe'
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

function MainHero() {
  useEffect(() => {
    GLOBE({
      el: "#vanta",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xD761DC,
      color2: 0xD761DC,
      size: 0.80,
      backgroundColor: 0xffffff,
    })
  }, [])
  const handleLinkClick = () => {
    scroll.scrollToTop();
  };
  return (
    <>
    <div className="mt-4 h-[90vh] w-full z-[-10] absolute md:block overflow-hidden flex items-center justify-center">
    <div id="vanta" className="mt-4 h-[90vh] w-full z-[-10] absolute md:block">
    </div>
    </div>

      <div className=" mx-5 md:mx-10 mt-[28vh] md:mt-[22vh] flex md:text-left md:flex-row min-h-[68vh]">
        <div className="md:w-[46%] md:mt-0 leading-[1.2] w-[80%] ">
          <div
            className="downtext text-[#a8ca55] bg-[#A8CA551A] md:flex hidden 
           w-[160px] h-[15px] px-[10px] py-[20px] text-[14px] font-bold rounded-full
          items-center justify-center"
          >
            IOS ANDROID WEB
          </div>
          <div
            id="data"
            className="text-[42px] lg:text-[4.2vw]  font-extrabold mb-[1.4rem] mt-[0vh] md:mt-6 pr-6 whitespace-normal tracking-wide"
          >
            THink P<span className="text-[#A8CA55] ">A</span>RTneR
            <span className="text-[#9686FC] ">, </span>N
            <span className="text-[#D761DC]">O</span>T AGenCY
          </div>
          <div className="downtext text-[16px] font-light m-[0 0 16px] text-[#1B1C19] mb-4 leading-6">
            Since 2022, we've helped lauch 8+ next-generation apps for
            startups and growing companines around the world. Let's Make It
            App'n!&#174;
            <div id="mainherobutton">
              <Link to="/contact">              
              <button
                className="bg-[#D761DC]
              mt-4 text-white py-[8px] px-[23px] border-none rounded-3xl font-bold hover:bg-black transition-all duration-500 ease-in-out" onClick={handleLinkClick}
              >
                {" "}
                Work With Us{" "}
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Services />
      </div>
      <div className="mt-12">
        <Projects />
      </div>
      <div className="mt-6">
        <Card />
      </div>

      <div className="mt-20">
        <Testominals />
      </div>
    </>
  );
}

export default MainHero;
