import workshop0 from '../images/workshop1.jpeg';
export const Data = [
    {
        id: "1",
        img: workshop0,
        name: 'AI Innovators Bootcamp',
        date:'December 7th, 2023',
        time:'2:00 pm',
        venue:"Institute of space technology Islamabad",
        type:"workshop",
        price:"Free",
        registerationlink:"https://forms.gle/WkrvHXDdrEm13yYP8",
        link:'/AI-Innovators-Bootcamp',
    },
    {
        id: 2,
        img: workshop0,
        name: 'AI Innovators Bootcamp 2',
        date:'September 30th, 2023',
        link:'/AI-Innovators',
    }
];