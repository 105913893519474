import React from 'react'
import { Link } from 'react-router-dom'
import dasboard from './images/dashboard.png'
// import second from './images/abouthero-bg.png'
function Details() {
    return (
        <div>
            <section class="min-h-[90vh]" style={{ backgroundColor: '#DC7CE4' }}>
                <div class="px-4 mx-auto max-w-[94%] sm:px-6 lg:px-8">
                    <div class="py-10 sm:py-16 lg:py-24 ">
                        <div class="grid items-center grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-x-8 ">
                            <div class="lg:order-2">
                                <img class="w-full shadow-xl rounded-xl" src={dasboard} alt="" />
                            </div>
                            <div class="lg:order-1">
                                <h2 id="solutiontitle" className='title text-[32px] leading-[1.2] font-normal mb-[1rem]'>About US</h2>
                                <p class="text-base sm:text-lg md:text-xl  text-gray-50 py-2">
                                    Proudly serving the world’s top asset finance and leasing companies with smart software technology for over four decades.
                                </p>
                                <div class="flex flex-col items-start mt-[1rem] sm:space-x-4 sm:flex-row sm:items-center">
                                    <Link to="/contact" title="" class="inline-flex items-center justify-center px-4 py-4 mt-5 text-base font-semibold text-white transition-all duration-200 bg-transparent border border-white rounded-md sm:mt-0 hover:bg-white hover:text-black" role="button"> Get In Touch </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Details
