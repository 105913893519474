import React from 'react'
import {Data} from "./data/data"
import { Link } from 'react-router-dom'

function Workshop() {
    return (
        <>
            <div class="container mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-8 md:my-0">
                {
                Data.map((workshop,key)=>(
                        <>
                            <div class="col-span-1 md:col-span-1 lg:col-span-1 bg-white shadow-md rounded-md overflow-hidden">
                                <Link to={workshop.link} class="block cursor-pointer transition duration-300 hover:text-blue-500">
                                    <img class="w-full" src={workshop.img} alt="" />
                                </Link>
                                <div class="p-4">
                                    <h5 class="font-semibold text-[24px] mb-[6px] leading-[1.2] text-[#1B1C19] capitalize">
                                        <Link to={workshop.link} class="cursor-pointer hover:text-[#D761DC] hover:underline">{workshop.name}</Link>
                                    </h5>
                                    <p class="text-gray-600">
                                        <i class="fa-solid fa-calendar-days"></i> {workshop.date}
                                    </p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>

        </>
    )
}

export default Workshop
