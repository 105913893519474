import React, { useState } from 'react';
import { Data } from "../Portfolio/Data/data";
// import second from '../Portfolio/Images/portfoliohero-bg.png';
import Search from '../Portfolio/Images/search.png';

function Portfolio() {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredCategory = Data.filter((project) => {
        const isCategoryMatch = !selectedCategory || project.category === selectedCategory;
        const isSearchMatch =
            project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.btntitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            project.category.toLowerCase().includes(searchQuery.toLowerCase());
        return isCategoryMatch && isSearchMatch;
    });

    return (
        <div className=''>
            <div className="flex min-w-screen justify-center items-center h-[100vh]" style={{ backgroundColor: '#948CFC' }}>
                < div className='w-2/3 sm:w-4/5 md:w-2/3' >
                    <div className='flex flex-col gap-5 sm:gap-6 md:gap-10 py-3 sm:py-4 md:py-6 justify-center items-center'>
                        <h2 id="solutiontitle" className='title text-[32px] text-center leading-[1.2] font-normal'>Our work</h2>
                        <p className='text-base sm:text-lg md:text-xl text-center text-white'>We’re our partner’s biggest fans. Check out some of the cool digital products we’ve built together!</p>
                    </div>
                    <div className='flex justify-center flex-wrap gap-1 sm:gap-3 md:gap-4 mt-2'>
                        <p onClick={() => handleCategoryClick('')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>ALL</p>
                        <p onClick={() => handleCategoryClick('Web Apps')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>Web Apps</p>
                        <p onClick={() => handleCategoryClick('Mobile Apps')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>Mobile Apps</p>
                        <p onClick={() => handleCategoryClick('Web Scrapping')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>Web Scrapping</p>
                        <p onClick={() => handleCategoryClick('Chatbots')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>Chatbots</p>
                        <p onClick={() => handleCategoryClick('AI & Automation')} className='bg-[#C4BEFD] text-white font-semibold sm:font-bold text-base sm:text-lg py-2 sm:py-3 px-5 sm:px-8 md:px-12 rounded-full cursor-pointer hover:bg-green-400 active:bg-white active:text-blue-400'>AI & Automation</p>
                    </div>
                </div >
            </div >

            <div className='flex justify-center'>
                <div className='flex items-center -mt-5 sm:-mt-9 bg-white rounded-full p-1 sm:p-2 w-fit px-2 sm:px-6 shadow-lg'>
                    <img className='w-6 h-6 sm:w-10 sm:h-10' width={40} height={40} src={Search} alt="Search Icon" />
                    <input
                        className='w-40 sm:w-60 py-2 sm:py-3 pl-2 sm:pl-3 pr-6 sm:pr-12 rounded-full cursor-text outline-none'
                        type="text"
                        placeholder='Search here...'
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <div className='flex justify-center items-center text-center align-items'>
                        <input className='font-bold pb-2 sm:pb-5 text-xl sm:text-3xl py-2 sm:py-3 px-5 sm:px-9 rounded-full cursor-pointer bg-green-400 hover:bg-black hover:text-white' type="button" value=">"
                        />
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-y-8 md:grid-cols-2 lg:grid-cols-3 md:items-center md:mx-10 items-center relative bg-white mt-5">
            {filteredCategory.map((li, key) => (
            <div
              key={key}
              className={`mx-4 md:min-h-[520px]  text-left border-none rounded-[15px] h-auto transform duration-400 ease-in bg-white  shadow-lg hover:shadow-2xl cursor-pointer
             mb-2 mt-2`}
            >
              <img src={li.img} alt={li.altdata} className='border-none rounded-t-[15px] md:min-h-[250px] md:max-h-[250px]'/>
              <div className='px-4 pb-4'>
              <h5 className='font-semibold text-[24px] mt-10 mb-[1.5rem] leading-[1.2] text-[#1B1C19] capitalize'>{li.title}</h5>
              <p className='font-light text-[16px] mb-2 text-[#1B1C19]'>{li.description}</p>

              {li.link && (
                <p className='flex justify-end'>
                  <a href={li.link} target='_blank' rel="noreferrer" className='bg-[#1B1C19] text-white text-[18px] font-semibold border-none px-[25px] py-[10px] transition-all duration-500 ease-linear  rounded-[50px] mb-[0%] hover:bg-[#A8CA55]'>Open</a>
                </p>
              )}
              </div>
            </div>
          ))}
    </div>


        </div >
    );
}

export default Portfolio;
