import project1 from '../MarkhorProjects/abaanmart.png';
import project2 from '../MarkhorProjects/bugtracker.png';
import project3 from '../MarkhorProjects/covid19screenertool.png';
import project4 from '../MarkhorProjects/fiverrdatascrapper.jpg';
import project5 from '../MarkhorProjects/mandimaster.png';
import project6 from '../MarkhorProjects/royalmarkhor.png';
import project7 from '../MarkhorProjects/techotils.png';
import project8 from '../MarkhorProjects/tufailhashmi.png';
import project9 from '../MarkhorProjects/zyndexllc.png';
import project10 from '../MarkhorProjects/mydriverental.jpeg';
import project11 from '../MarkhorProjects/cobracraze.jpeg';
import project12 from '../MarkhorProjects/mywisdomhub.png';
import project13 from '../MarkhorProjects/888hml.png';
import project14 from '../MarkhorProjects/buildmate.png';

export const Data = [
    {
        no: 8,
        img: project8,
        category: 'Web Apps',
        title: 'Tufail Hashmi Portfolio',
        description:"Discover Tufail Hashmi Portfolio: Showcasing Tech Excellence.Explore sleek websites, user-friendly apps, and expert designs. See innovation come to life, project by project.",
        btntitle: 'Explore',
        altdata: '8 Project',
        link:'https://tufailhashmi.com/',
    },
    {
        no: 7,
        img: project7,
        category: 'Web Apps',
        title: 'Techotils  Startup',
        description:'Techotils is an AIOT (Artificial Intelligence in Internet Of Things) Startup focusing on Healthcare Industry. Their website features Three JS Animation which sets the bar of their working standard in the industry.',
        btntitle: 'Check it Out',
        altdata: '7 Project',
        link:'https://techotils-website.vercel.app/',
    },
    {
        no: 13,
        img: project13,
        category: 'Web Apps',
        title: 'Hml Accident Services',
        description:"HML Accident Services is a unique firm that specializes in getting large settlements as well as verdicts. Unlike other firms, we give you the individualized attention...",
        btntitle: 'Explore',
        altdata: '13 Project',
        link:'https://888hml.com/',
    },
    {
        no: 14,
        img: project14,
        category: 'Web Apps',
        title: 'BuildMate Startup',
        description:"Experience AI-powered construction estimation with BuildMate. Get accurate project insights and streamline workflows for efficient planning...",
        btntitle: 'Explore',
        altdata: '14 Project',
        link:'https://buildmate-live.netlify.app/',
    },
    {
        no: 9,
        img: project9,
        category: 'Web Apps',
        title: 'Zyndex Bussiness Solution',
        description:"Unlock Zyndex's Ecommerce Mastery. From inventory to checkout, harness powerful tools for efficient online ventures. Simplify management, delight customers, and drive profitability.",
        btntitle: 'View Details',
        altdata: '9 Project',
        link:'https://zyndexllc.com/',
    },
    {
        no: 2,
        img: project2,
        category: 'Web Apps',
        title: 'Bug Tracker Product',
        description:"Streamline software development with efficient bug tracking. Detect, report, and resolve issues swiftly for enhanced project delivery and client satisfaction.",
        btntitle: 'Our Process',
        altdata: '2 Project',
        link:'https://muhammadmobeen.com/bug-tracker',
    },
    {
        no: 10,
        img: project10,
        category: 'Web Apps',
        title: 'Rental Car Booking Website',
        description:"We're thrilled to provide a customized car rental solution. Our seamless database management is tailored exclusively for our valued client's convenience.",
        btntitle: 'View Details',
        altdata: '10 Project',
        link:'https://mydriverentals.com/',
    },
    {
        no: 11,
        img: project11,
        category: 'Web Apps',
        title: 'CobraCraze Ecommerce',
        description:"Unleash the power of Cobracraze: Our premier ecommerce solution, customer satisfaction, effortless shopping experiences and unparalleled profitability",
        btntitle: 'View Details',
        altdata: '11 Project',
        link:'https://cobracraze.com/',
    },
    {
        no: 12,
        img: project12,
        category: 'Web Apps',
        title: 'MY Wisdom Hub',
        description:"Dive into a world of insightful articles and thought-provoking discussions at MY Wisdom Hub. Explore a diverse range of topics, from technology trends to personal development tips.",
        btntitle: 'Explore',
        altdata: '12 Project',
        link:'https://mywisdomhub.com/',
    },
    {
        no: 1,
        img: project1,
        category: 'Web Apps',
        title: 'AbaanMart Ecommerce Solution',
        description:"Unlock AbaanMart Ecommerce Mastery. From inventory to checkout, Simplify management, delight customers, and drive profitability.",
        btntitle: 'Read Up',
        altdata: '1 Project',
        link:'https://abaanmart.com/',
    },
    {
        no: 5,
        img: project5,
        category: 'Web Apps',
        title: 'MandiMaster Bridging Farmers and Markets',
        description:'MandiMaster streamlines agricultural trade by bridging farmers directly to markets, fostering efficiency and empowering communities.',
        btntitle: 'Learn More',
        altdata: '5 Project',
        link:'https://markhorverse.pythonanywhere.com/',
    },
    {
        no: 4,
        img: project4,
        category: 'Web Scrapping',
        title: 'Fiverr Competitor Analysis Web Scraper',
        description:'Fiverr Scraper provides deep competitor analysis across any niche. Our advanced automation package offers industrial-grade scraping and insightful automation systems.',
        btntitle: 'View Details',
        altdata: '4 Project',
        link:'https://github.com/muhammad-mobeen/Fiverr-Scrapper',
    },
    {
        no: 3,
        img: project3,
        category: 'Web Apps',
        title: "COVID-19 Screening Tool",
        description:"We've had the honor of partnering with incredible companies and entrepreneurs over the years. Come see what we've been up to!",
        btntitle: 'Check it Out',
        altdata: '3 Project',
        link:'https://muhammadmobeen.com/covid19',
    },
    {
        no: 6,
        img: project6,
        category: 'Web Apps',
        title: 'Royal Markhor Crypto Wallet',
        description:'Royal Markhor Crypto Wallet: Safeguarding Your Digital Assets with Unparalleled Security and Convenience',
        btntitle: 'Explore',
        altdata: '6 Project',
        link:'https://muhammadmobeen.com/markhor',
    },
];
